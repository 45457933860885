import {setDefaultHeader} from '../../api/request';
import {isNotNullOrUndefined} from './TypeHelper';
import moment from 'moment/moment';

function parseMaintenanceToken(token)
{
    if (isNotNullOrUndefined(token))
    {
        let jsonPayload = decodeURIComponent(atob(token).split('').map(character => {
            return '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    return null;
}

function getTokenExpirationDate(token)
{
    let decodedToken = parseMaintenanceToken(token);

    if (isNotNullOrUndefined(decodedToken))
    {
        //Exp represents the Seconds Since the Epoch
        return moment.unix(decodedToken.expires_at);
    }

    // if no MaintenanceToken is in LocalStorage, return start of year
    // to ensure Session will be evaluated as invalid
    return moment().startOf('year');
}

function isTokenValid(token)
{
    let tokenExpirationDate = getTokenExpirationDate(token);

    return moment().isSameOrBefore(tokenExpirationDate);
}

export default {
    MAINTENANCE_BYPASS_TOKEN_STORAGE_KEY: 'maintenance_token',
    MAINTENANCE_BYPASS_TOKEN_HEADER_KEY: 'X-Maintenance-Token',
    setMaintenanceBypassToken(token) {
        localStorage.setItem(this.MAINTENANCE_BYPASS_TOKEN_STORAGE_KEY, token);
        setDefaultHeader(this.MAINTENANCE_BYPASS_TOKEN_HEADER_KEY, token);
    },
    removeMaintenanceBypassToken() {
        localStorage.removeItem(this.MAINTENANCE_BYPASS_TOKEN_STORAGE_KEY);
        setDefaultHeader(this.MAINTENANCE_BYPASS_TOKEN_HEADER_KEY, undefined);
    },
    checkMaintenanceBypassToken() {
        let token = localStorage.getItem(this.MAINTENANCE_BYPASS_TOKEN_STORAGE_KEY);

        if (isTokenValid(token))
        {
            this.setMaintenanceBypassToken(token);
            return true;
        }
        else
        {
            this.removeMaintenanceBypassToken();
            return false;
        }
    }
}