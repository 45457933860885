<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="8" offset-md="2">
                <v-card>
                    <v-card-text align="center">
                        <v-img class="elevation-0" src="../assets/img/logo-ratis.png" :max-width="logoMaxWidth"/>
                        <br/>
                        <h1>Willkommen im Mandanten-Portal</h1>
                        <br/>
                        <p>
                            Hier können Sie uns die für Ihren Fall benötigten Informationen und Unterlagen direkt
                            zukommen lassen.
                            <br/>
                            <br/>
                            Um auf die Funktionen unseres Mandanten-Portals zugreifen zu können, loggen Sie sich bitte
                            mit Ihren Zugangsdaten ein.
                            <br/>
                            <br/>
                            Sollten Sie noch keine Zugangsdaten haben, führen Sie bitte zunächst eine Registrierung
                            durch.
                            <br/>
                            <br/>
                            Bei Fragen oder Problemen mit der Verwendung des Mandanten-Portals senden Sie uns eine
                            E-Mail an <a href="mailto:info@ratis.de">info@ratis.de</a>.
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'home',
    computed: {
        logoMaxWidth() {
            if (this.$vuetify.breakpoint.xsOnly)
                return '80%';
            else if (this.$vuetify.breakpoint.smOnly)
                return '50%';

            return '40%';
        }
    }
};
</script>

<style scoped>
.portalcaption {
    font-size: 3em;
    font-weight: bold;
    line-height: normal;
}
</style>