import {removeApiToken} from '../../api/request';

export const user = {
    namespaced: true,
    state: {
        userData: {
            id:         null,
            email:      null,
            akten:      null,
            loggedIn:   false,
            cases:      [],
            role: 1,
        }
    },
    getters: {
        isLoggedIn: state => state.userData.loggedIn,
        user: state => {
            return {
                id:     state.userData.id,
                email:  state.userData.email,
                akten:  state.userData.akten,
                cases:  state.userData.cases,
                role:   state.userData.role
            }
        }
    },
    actions: {
        login({commit})
        {
            commit('setLogin', true);
        },
        logout({commit})
        {
            commit('setLogin', false);
            commit('resetUserData');
        },
        setUser({commit}, userData)
        {
            commit('setUser', userData);
        }
    },
    mutations: {
        setLogin: (state, loginState) => (state.userData.loggedIn = loginState),
        setUser: function(state, userData)
        {
            state.userData.id                   = userData.id;
            state.userData.email                = userData.email;
            state.userData.akten                = userData.akten;
            state.userData.cases                = userData.cases;
            state.userData.role                 = userData.role;
        },
        resetUserData: function(state)
        {
            state.userData.id                   = null;
            state.userData.email                = null;
            state.userData.akten                = null;
            state.userData.cases                = [];
            state.userData.role                 = null;
            removeApiToken();
        }
    }
};
