import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global.js';
import { user } from './modules/user.js'
import { alert } from "./modules/alert.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        finishedInitialization: false,
        maintenanceModeActivated: false,
        appAvailable: true,
        loginPreviousRoute: null
    },
    mutations: {
        setFinishedInitialization: (state) => (state.finishedInitialization = true),
        setMaintenanceModeActivated: (state, activeState) => (state.maintenanceModeActivated = activeState),
        setAppAvailable: (state, available) => (state.appAvailable = available),
        setLoginPreviousRoute: (state, previous) => (state.loginPreviousRoute = previous)
    },
    actions: {
        finishedInitialization: function({commit})
        {
            commit('setFinishedInitialization');
        },
        activateMaintenanceMode: function({commit})
        {
            commit('setMaintenanceModeActivated', true);
        },
        deactivateMaintenanceMode: function({commit})
        {
            commit('setMaintenanceModeActivated', false);
        },
        appNotAvailable: function({commit})
        {
            commit('setAppAvailable', false);
        },
        appAvailable: function({commit})
        {
            commit('setAppAvailable', true);
        },
        setLoginPreviousRoute: function ({commit}, value)
        {
            commit('setLoginPreviousRoute', value);
        }
    },
    getters: {
        isInitializationFinished: state => state.finishedInitialization,
        isMaintenanceModeActivated: state => state.maintenanceModeActivated,
        isAppAvailable: state => state.appAvailable,
        loginPreviousRoute: state => state.loginPreviousRoute,
    },
    modules: {
        global,
        user,
        alert
    }
});
