export const alert = {
  namespaced: true,
  state: {
    messages: [],
    axiosBusy: 0
  },
  getters: {
    messages: state => state.messages,
    axiosIsBusy: state => (state.axiosBusy === 0 ? false : true)
  },
  mutations: {
    addMessage(state, message) {
      state.messages.push(message);
      const timeout = message.type === "error" ? 60000 : 5000;
      setTimeout(function() {
        state.messages.shift();
      }, timeout);
    },
    setAxiosBusy(state) {
      state.axiosBusy++;
    },
    setAxiosIdle(state) {
      state.axiosBusy--;
    }
  },
  actions: {
    add(context, message) {
      context.commit("addMessage", message);
    },
    axiosBusy(context) {
      context.commit("setAxiosBusy");
    },
    axiosIdle(context) {
      context.commit("setAxiosIdle");
    }
  }
};
