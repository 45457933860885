import {isNotNullOrUndefined} from '../helper/shared/TypeHelper';

export default {
    KUENDIGUNG: 0,
    EMISSION: 1,
    TRANSPORT: 2,
    FLIGHTS: 3,
    BANK_FEES: 4,
    GYM_FEES: 5,
    VEHICLE_FINANCING: 6,
    EMISSION_COMPENSATION: 7,
    PHISHING: 8,
    PWNED: 9,
    GENERAL_TRAVEL: 10,
    hasUserCaseOfCaseType(user, caseType)
    {
        if (isNotNullOrUndefined(user) && user.cases.some(userCase => userCase.type === caseType))
            return true
        else
            return false;
    }
};
