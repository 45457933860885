import Vue from 'vue'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css';
import 'typeface-roboto/index.css';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/';
import compressor from 'compressorjs';
import {checkApiToken, checkHealth} from '@/api/request';
import {apiProfile} from "./api/user";
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';
import moment from 'moment';
import DatetimePicker from 'vuetify-datetime-picker';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import VueSignature from 'vue-signature-pad';

Vue.component('v-text-field', VTextField);

Vue.config.productionTip = false;
Vue.prototype.$compressor = compressor;

Vue.use(VCurrencyField, {
    allowNegative: false,
    locale: 'de-DE',
    min: 0
});

moment.locale('de');
Vue.use(DatetimePicker);

Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: 'AIzaSyDDQ37CQEXqQD0hddwpor7KA69QS5g7HS0',
    locale: 'de'
});

Vue.use(VueSignature);

let reloadUserData = async function()
{
    let res = await apiProfile();

    if(res)
    {
        store.dispatch('user/setUser', res.data);
        store.dispatch('user/login');
    }

    store.dispatch('finishedInitialization');
};

let checkHealthInitial = async function()
{
    let canContinue = await checkHealth(true);

    if (canContinue)
    {
        //check of route is not possible here, because sometimes it is not set
        let isNoDirectRoute = document.URL.indexOf('direkt') === -1;
        if(isNoDirectRoute && checkApiToken())
            reloadUserData();
        else
            store.dispatch('finishedInitialization');
    }
    else
    {
        await store.dispatch('finishedInitialization');
        await store.dispatch('appNotAvailable');
        await router.push({name: 'app-not-available'}, function() {}, function() {});
    }
}

checkHealthInitial();

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
