import axios from "axios";
import store from "@/store";
import router from '@/router';
import {isTokenValid} from '../helper/shared/JWTTokenHelper';
import {isNotNullOrUndefined} from '../helper/shared/TypeHelper';
import MaintenanceMode from '../helper/shared/MaintenanceMode';

const checkHealthClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000,
  headers: { Accept: "application/json" }
});

const client = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 360000,
  headers: { 'Accept': 'application/json' }
});

client.interceptors.request.use(async function (config) {
  let canContinue = await checkHealth();

  if (canContinue)
    return config;
  else
  {
    await store.dispatch('appNotAvailable');
    await router.push({name: 'app-not-available'}, function() {}, function() {});

    return Promise.reject({message: 'Anwendung steht aktuell nicht zur Verfügung'});
  }
});

client.interceptors.response.use(function (response){
      return response;
    },
    function (error){
      if (error.response.status === 401)
      {
        store.dispatch('user/logout');
        store.dispatch('setLoginPreviousRoute', router.currentRoute.fullPath);
        router.push({name: 'login'});
      }

      return Promise.reject(error);
    });

export function setApiToken(token) {
  localStorage.setItem('jwt_token', token);
  client.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export function removeApiToken()
{
  localStorage.removeItem('jwt_token');
}

export function checkApiToken() {
  let api_token = localStorage.getItem('jwt_token');

  if (api_token && isTokenValid(api_token))
  {
    setApiToken(api_token);

    return true;
  }
  else
    return false;
}

export async function request(args) {
  try {
    store.dispatch("alert/axiosBusy");
    const response = await client.request(args);
    store.dispatch("alert/axiosIdle");

    return response.data;
  }
  catch (error)
  {
    let message = {
      type: "error"
    };

    if (error.response)
      message.message = resolveResponse(error.response);
    else if (error.request)
      message.message = "Could not send request: " + JSON.stringify(error.request);
    else
      message.message = "Unknown axios error: " + error.message;

    store.dispatch("alert/add", message);
    store.dispatch("alert/axiosIdle");
  }
}

function resolveResponse(response)
{
  if(response.status === 401)
    return 'Sie sind nicht authentifiziert/Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich ein.';

  return "HTTP " + response.status + ": " + response.data.message
}

export function setDefaultHeader(headerName, headerValue)
{
    client.defaults.headers.common[headerName] = headerValue;
}

export async function checkHealth(checkQueryParamForSecret = false)
{
    let canContinue = true;
    try
    {
        let response = await checkHealthClient.request({
            url: '/api/check-health',
            method: 'get'
        });

        if (response && response.data.is_down)
        {
            store.dispatch('activateMaintenanceMode');

            let bypassSecretQueryParam = router.currentRoute.query.maintenanceSecret;

            if (checkQueryParamForSecret && isNotNullOrUndefined(bypassSecretQueryParam))
            {
                let bypassResponse = await checkHealthClient.request({
                    url: '/api/check-maintenance-bypass-secret',
                    method: 'post',
                    data: {
                        secret: bypassSecretQueryParam
                    }
                });

                if (bypassResponse && bypassResponse.data.bypass_token)
                    MaintenanceMode.setMaintenanceBypassToken(bypassResponse.data.bypass_token);
            }

            if (MaintenanceMode.checkMaintenanceBypassToken() === false)
                canContinue = false;
        }
    }
    catch (error)
    {
        canContinue = false;
    }

    return canContinue;
}
