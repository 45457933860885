<template>
  <v-app>
    <Navigation />

    <v-main>
      <router-view/>
    </v-main>

    <div style="position: fixed; width: 450px; z-index: 10000; top: 10px; right: 10px;">
      <v-alert v-for="(msg, i) in messages" :key="i" :type="msg.type" dismissible>
        {{ msg.message }}
      </v-alert>
    </div>
    <cookie-law transitionName="fade" theme="dark-lime" buttonText="Verstanden" message="Das RATIS-Portal verwendet ausschließlich funktionale Cookies. Mit der Benutzung des Portals erklären Sie sich hiermit einverstanden."/>
  </v-app>
</template>

<script>
  import Navigation from './components/base/Navigation';
  import CookieLaw from 'vue-cookie-law';
  import { mapGetters } from "vuex";

  export default {
    name: 'App',
    components: {
      Navigation,
      CookieLaw
    },
    data: () => ({
      //
    }),
    computed: {
      ...mapGetters({
        messages: "alert/messages",
      })
    },
  };
</script>

<style>
  /*Disables upload progress bar of dropzone component as it is not needed with the current design.*/
  .dz-progress {
    display:none !important;
  }
</style>
