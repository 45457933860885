import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import {checkAppNotAvailable, checkAuthentication, checkNoAuthentication} from './guards/routeguards';
import DirectCaseRouteNames from './constants/directMandate/directCaseRouteNames';

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
            meta: {
                title: "Startseite"
            }
        },
        {
            path: '/app-not-available',
            name: 'app-not-available',
            component: () => import(/* webpackChunkName: "app-not-available" */ './views/AppNotAvailable.vue'),
            meta: {
                title: 'Anwendung nicht verfügbar'
            },
            beforeEnter: checkAppNotAvailable
        },
        {
            path: "/impressum",
            name: "impressum",
            component: () => import(/* webpackChunkName: "impressum" */ "./views/Impressum.vue"),
            meta: {
                title: "Impressum"
            }
        },
        {
            path: "/datenschutz",
            name: "datenschutz",
            component: () => import(/* webpackChunkName: "datenschutz" */ "./views/Datenschutz.vue"),
            meta: {
                title: "Datenschutz"
            }
        },
        {
            path: "/login",
            name: "login",
            component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
            meta: {
                title: "Login"
            },
            beforeEnter: checkNoAuthentication
        },
        {
            path: "/signup",
            name: "signup",
            component: () => import(/* webpackChunkName: "signup" */ "./views/Signup.vue"),
            meta: {
                title: "Registrierung"
            },
            beforeEnter: checkNoAuthentication
        },
        {
            path: "/confirm",
            name: "confirm",
            component: () => import(/* webpackChunkName: "confirm" */ "./views/Confirm.vue"),
            meta: {
                title: "Bestätigung"
            },
            beforeEnter: checkNoAuthentication
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import(/* webpackChunkName: "forgot-password" */ "./views/ForgotPassword.vue"),
            meta: {
                title: "Passwort vergessen"
            },
            beforeEnter: checkNoAuthentication
        },
        {
            path: "/reset-password",
            name: "reset-password",
            component: () => import(/* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"),
            meta: {
                title: "Passwort vergeben"
            },
            beforeEnter: checkNoAuthentication
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: () => import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
            meta: {
                title: "Übersicht"
            },
            beforeEnter: checkAuthentication
        },
        {
            path: "/kuendigung/case",
            name: "kuendigungs-case",
            component: () => import(/* webpackChunkName: "kuendigungs-case" */ "./views/KuendigungsCase.vue"),
            meta: {
                title: "Ihr Kündigungsfall"
            },
            beforeEnter: checkAuthentication
        },
        {
            path: "/emissions",
            name: "emissions",
            component: () => import(/* webpackChunkName: "emissions" */ "./views/emissions/Index.vue"),
            meta: {
                title: "Abgasskandal"
            },
            beforeEnter: checkAuthentication,
            children: [
                {
                    path: 'create-case',
                    name: 'create-emissions-case',
                    component: () => import(/* webpackChunkName: "create-emissions-case" */ './views/emissions/CreateEmissionsCase.vue'),
                    meta: {
                        title: "Fall im Abgasskandal anlegen"
                    },
                },
                {
                    path: 'case',
                    name: 'emissions-case',
                    component: () => import(/* webpackChunkName: "emissions-case" */ './views/emissions/EmissionsCase.vue'),
                    meta: {
                        title: "Ihr Fall im Abgasskandal"
                    },
                },
            ]
        },
        {
            path: "/transport",
            name: "transport",
            component: () => import(/* webpackChunkName: "transport" */ "./views/transport/Index.vue"),
            meta: {
                title: "Verkehr"
            },
            beforeEnter: checkAuthentication,
            children: [
                {
                    path: 'create-case',
                    name: 'create-transport-case',
                    component: () => import(/* webpackChunkName: "create-transport-case" */ './views/transport/CreateCase.vue'),
                    meta: {
                        title: "Verkehrsunfall anlegen"
                    },
                },
                {
                    path: 'case',
                    name: 'transport-case',
                    component: () => import(/* webpackChunkName: "transport-case" */ './views/transport/TransportCase.vue'),
                    meta: {
                        title: "Ihr Verkehrsunfall"
                    },
                },
            ]
        },
        {
            path: "/bank-fees",
            name: "bankFees",
            component: () => import(/* webpackChunkName: "bankFees" */ "./views/bankFees/Index.vue"),
            meta: {
                title: "Bankgebühren"
            },
            beforeEnter: checkAuthentication,
            children: [
                // {
                //     path: 'create-case',
                //     name: 'create-bank-fees-case',
                //     component: () => import(/* webpackChunkName: "create-bank-fees-case" */ './views/bankFees/CreateCase.vue'),
                //     meta: {
                //         title: "Fall zu Bankgebühren anlegen"
                //     },
                // },
                {
                    path: 'case',
                    name: 'bank-fees-case',
                    component: () => import(/* webpackChunkName: "bank-fees-case" */ './views/bankFees/BankFeesCase.vue'),
                    meta: {
                        title: "Ihr Fall zu Bankgebühren"
                    },
                },
            ]
        },
        {
            path: "/gym-fees",
            name: "gymFees",
            component: () => import(/* webpackChunkName: "gymFees" */ "./views/gymFees/Index.vue"),
            meta: {
                title: "Fitnessstudiobeiträge"
            },
            beforeEnter: checkAuthentication,
            children: [
                // {
                //     path: 'create-case',
                //     name: 'create-gym-fees-case',
                //     component: () => import(/* webpackChunkName: "create-gym-fees-case" */ './views/gymFees/CreateCase.vue'),
                //     meta: {
                //         title: "Fall zu Fitnessstudiobeiträgen anlegen"
                //     },
                // },
                {
                    path: 'case',
                    name: 'gym-fees-case',
                    component: () => import(/* webpackChunkName: "gym-fees-case" */ './views/gymFees/GymFeesCase.vue'),
                    meta: {
                        title: "Ihr Fall zu Fitnessstudiobeiträgen"
                    },
                },
            ]
        },
        {
            path: "/vehicle-financing",
            name: "vehicleFinancing",
            component: () => import(/* webpackChunkName: "vehicleFinancing" */ "./views/vehicleFinancing/Index.vue"),
            meta: {
                title: "KFZ-Widerruf"
            },
            beforeEnter: checkAuthentication,
            children: [
                // {
                //     path: 'create-case',
                //     name: 'create-vehicle-financing-case',
                //     component: () => import(/* webpackChunkName: "create-vehicle-financing-case" */ './views/vehicleFinancing/CreateCase.vue'),
                //     meta: {
                //         title: "Fall zu KFZ-Widerruf anlegen"
                //     },
                // },
                {
                    path: 'case',
                    name: 'vehicle-financing-case',
                    component: () => import(/* webpackChunkName: "vehicle-financing-case" */ './views/vehicleFinancing/VehicleFinancingCase.vue'),
                    meta: {
                        title: "Ihr Fall zum KFZ-Widerruf"
                    },
                },
            ]
        },
        {
            path: "/phishing",
            name: "phishing",
            component: () => import(/* webpackChunkName: "phishing" */ "./views/phishing/Index.vue"),
            meta: {
                title: "Phishing"
            },
            beforeEnter: checkAuthentication,
            children: [
                // {
                //     path: 'create-case',
                //     name: 'create-phishing-case',
                //     component: () => import(/* webpackChunkName: "create-phishing-case" */ './views/phishing/CreateCase.vue'),
                //     meta: {
                //         title: "Fall zu Phishing anlegen"
                //     },
                // },
                {
                    path: 'case',
                    name: 'phishing-case',
                    component: () => import(/* webpackChunkName: "phishing-case" */ './views/phishing/PhishingCase.vue'),
                    meta: {
                        title: "Ihr Fall zu Phishing"
                    },
                },
            ]
        },
        {
            path: "/flights",
            name: "flights",
            component: () => import(/* webpackChunkName: "flights" */ "./views/flights/Index.vue"),
            meta: {
                title: "Flugerspätung"
            },
            beforeEnter: checkAuthentication,
            children: [
                // {
                //     path: 'create-case',
                //     name: 'create-flights-case',
                //     component: () => import(/* webpackChunkName: "create-flights-case" */ './views/flights/CreateCase.vue'),
                //     meta: {
                //         title: "Fall wegen Flugverspätung anlegen"
                //     },
                // },
                {
                    path: 'case',
                    name: 'flights-case',
                    component: () => import(/* webpackChunkName: "flights-case" */ './views/flights/FlightsCase.vue'),
                    meta: {
                        title: "Ihr Fall wegen Flugverspätung"
                    },
                },
            ]
        },
        {
            path: "/pwned",
            name: "pwned",
            component: () => import(/* webpackChunkName: "pwned" */ "./views/pwned/Index.vue"),
            meta: {
                title: "Facebook Datenskandal"
            },
            beforeEnter: checkAuthentication,
            children: [
                {
                    path: 'case',
                    name: 'pwned-case',
                    component: () => import(/* webpackChunkName: "pwned-case" */ './views/pwned/PwnedCase.vue'),
                    meta: {
                        title: "Ihr Fall zum Facebook Datenskandal"
                    },
                },
            ]
        },
        {
            path: "/general",
            name: "general",
            component: () => import(/* webpackChunkName: "general" */ "./views/general/Index.vue"),
            children: [
                {
                    path: "file-uploader/:apiToken",
                    name: "file-uploader",
                    component: () => import(/* webpackChunkName: "file-uploader" */ "./views/FileUploader.vue"),
                    meta: {
                        title: "Dokument/-e hochladen"
                    }
                },
                {
                    path: "file-downloader/:apiToken",
                    name: "file-downloader",
                    component: () => import(/* webpackChunkName: "file-downloader" */ "./views/FileDownloader.vue"),
                    meta: {
                        title: "Dokument/-e herunterladen"
                    }
                },
                {
                    path: "get-flights-support/:uid",
                    name: "get-flights-support",
                    component: () => import(/* webpackChunkName: "get-flights-support" */ "./views/flights/GetSupport.vue"),
                    meta: {
                        title: "Wir haben die Vollmacht an Sie gesendet!"
                    }
                },
                {
                    path: "save-airline-paid/:uid",
                    name: "save-airline-paid",
                    component: () => import(/* webpackChunkName: "save-airline-paid" */ "./views/flights/AirlinePaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "save-bank-paid/:uid",
                    name: "save-bank-paid",
                    component: () => import(/* webpackChunkName: "save-bank-paid" */ "./views/bankFees/BankPaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "save-gym-paid/:uid",
                    name: "save-gym-paid",
                    component: () => import(/* webpackChunkName: "save-gym-paid" */ "./views/gymFees/GymPaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "save-lender-paid/:uid",
                    name: "save-lender-paid",
                    component: () => import(/* webpackChunkName: "save-lender-paid" */ "./views/vehicleFinancing/LenderPaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "save-manufacturer-paid/:uid",
                    name: "save-manufacturer-paid",
                    component: () => import(/* webpackChunkName: "save-manufacturer-paid" */ "./views/emissions/emissionCompensation/ManufacturerPaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "save-phishing-paid/:uid",
                    name: "save-phishing-paid",
                    component: () => import(/* webpackChunkName: "save-phishing-paid" */ "./views/phishing/BankPaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "get-cancellation-support/:uid",
                    name: "get-cancellation-support",
                    component: () => import(/* webpackChunkName: "get-cancellation-support" */ "./views/cancellation/GetSupport.vue"),
                    meta: {
                        title: "Wir werden uns umgehend bei Ihnen melden!"
                    }
                },
                {
                    path: "save-employer-accepted/:uid",
                    name: "save-employer-accepted",
                    component: () => import(/* webpackChunkName: "save-employer-accepted" */ "./views/cancellation/EmployerAccepted.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "save-facebook-paid/:uid",
                    name: "save-facebook-paid",
                    component: () => import(/* webpackChunkName: "save-facebook-paid" */ "./views/pwned/FacebookPaid.vue"),
                    meta: {
                        title: "Vielen Dank für Ihr Vertrauen!"
                    }
                },
                {
                    path: "get-life-insurance-support/:uid",
                    name: "get-life-insurance-support",
                    component: () => import(/* webpackChunkName: "get-life-insurance-support" */ "./views/lifeInsurance/GetSupport.vue"),
                    meta: {
                        title: "Wir werden uns umgehend bei Ihnen melden!"
                    }
                },
                {
                    path: "get-cancellation-compensation-check-support/:uid",
                    name: "get-cancellation-compensation-check-support",
                    component: () => import(/* webpackChunkName: "get-cancellation-compensation-check-support" */ "./views/legacy/calculateCompensation/GetSupport.vue"),
                    meta: {
                        title: "Wir werden uns umgehend bei Ihnen melden!"
                    }
                },
                {
                    path: "get-unemployed-registration-support/:uid",
                    name: "get-unemployed-registration-support",
                    component: () => import(/* webpackChunkName: "get-unemployed-registration-support" */ "./views/legacy/registerAsUnemployed/GetSupport.vue"),
                    meta: {
                        title: "Wir werden uns umgehend bei Ihnen melden!"
                    }
                },
            ]
        },
        {
            path: "/direkt",
            name: "direct-mandate",
            component: () => import(/* webpackChunkName: "direct-mandate" */ "./views/directMandate/Index.vue"),
            meta: {
                title: "Direktmandat"
            },
            children: [
                {
                    path: "flug",
                    name: DirectCaseRouteNames.FLIGHT,
                    component: () => import(/* webpackChunkName: "direct-mandate" */ "./views/directMandate/CreateCase.vue"),
                    meta: {
                        title: "Fall anlegen"
                    },
                    alias: '/flug-direkt'
                },
                {
                    path: "reise",
                    name: DirectCaseRouteNames.GENERAL_TRAVEL,
                    component: () => import(/* webpackChunkName: "direct-mandate" */ "./views/directMandate/CreateCase.vue"),
                    meta: {
                        title: "Fall anlegen"
                    },
                    alias: '/reise-direkt'
                },
                {
                    path: "facebook",
                    name: DirectCaseRouteNames.FACEBOOK,
                    component: () => import(/* webpackChunkName: "direct-mandate" */ "./views/directMandate/CreateCase.vue"),
                    meta: {
                        title: "Fall anlegen"
                    },
                    alias: '/facebook-direkt'
                },
            ]
        },
        {
            path: "*",
            name: "page-not-found",
            component: () => import(/* webpackChungName: "page-not-found" */ "./views/PageNotFound.vue"),
            meta: {
                title: "Seite nicht gefunden"
            }
        }
    ]
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  //TODO prüfung ob route zulässig?!

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  let nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle)
    document.title = nearestWithTitle.meta.title;
  else
    document.title = 'Portal';  //TODO: constant? or global config?

  next();
});

export default router;
