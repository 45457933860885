import { request } from "@/api/request";

export async function apiRegister(args) {
      const rv = await request({
              url: "/api/register",
              method: "post",
              data: args
            });
      return rv;
}

export async function apiConfirm(args) {
      const rv = await request({
              url: "/api/confirm",
              method: "post",
              data: args
            });
      return rv;
}

export async function apiLogin(args) {
      const rv = await request({
              url: "/api/login",
              method: "post",
              data: args
            });
      return rv;
}

export async function apiLostPassword(args) {
      const rv = await request({
              url: "/api/lost-password",
              method: "post",
              data: args
            });
      return rv;
}

export async function apiResetPassword(args) {
      const rv = await request({
              url: "/api/reset-password",
              method: "post",
              data: args
            });
      return rv;
}

export async function apiLogout(args) {
      const rv = await request({
              url: "/api/logout",
              method: "post",
              data: args
            });
      return rv;
}

export async function apiProfile() {
      const rv = await request({
              url: "/api/profile",
              method: "get"
            });
      return rv;
}