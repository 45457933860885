export default {
    MANDATOR: {
        value: 1,
        text: 'Mandant'
    },
    EMPLOYEE: {
        value: 3,
        text: 'Mitarbeiter'
    },
    MANAGER: {
        value: 7,
        text: 'Mitarbeiter (Verwaltung/Teamleitung)'
    },
    HUMAN_RESOURCES: {
        value: 15,
        text: 'Mitarbeiter (Personalabteilung)'
    },
    ADMIN: {
        value: 2047,
        text: 'Administrator'
    },
    toArray()
    {
        return [
            this.MANDATOR,
            this.EMPLOYEE,
            this.MANAGER,
            this.HUMAN_RESOURCES,
            this.ADMIN
        ]
    },
    getRoleByValue(roleValue)
    {
        //Attention! Order of this if/else statement is important as first matching value will be returned.

        if((this.ADMIN.value & roleValue) === this.ADMIN.value)
            return this.ADMIN;
        else if((this.HUMAN_RESOURCES.value & roleValue) === this.HUMAN_RESOURCES.value)
            return this.HUMAN_RESOURCES;
        else if((this.MANAGER.value & roleValue) === this.MANAGER.value)
            return this.MANAGER;
        else if((this.EMPLOYEE.value & roleValue) === this.EMPLOYEE.value)
            return this.EMPLOYEE;
        else if((this.MANDATOR.value & roleValue) === this.MANDATOR.value)
            return this.MANDATOR;

        return null;
    }
}