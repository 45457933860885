<template>
    <div>
        <v-navigation-drawer
                v-if="showNavigationAndLoginInfo"
                v-model="drawer"
                app
                clipped>
            <v-list dense>
                <div v-for="navItem in computedNavigationItems" :key="navItem.text">
                    <v-list-item v-if="!navItem.subLinks" router :to="navItem.route">
                        <v-list-item-action>
                            <v-icon>{{navItem.icon}}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{navItem.text}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group v-else :prepend-icon="navItem.icon">
                        <template v-slot:activator>
                            <v-list-item class="pl-0">
                                <v-list-item-content>
                                    <v-list-item-title>{{ navItem.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item v-for="subLink in navItem.subLinks" :to="subLink.route" :key="subLink.text">
                            <v-list-item-title v-text="subLink.text"></v-list-item-title>
                            <v-icon>{{subLink.icon}}</v-icon>
                        </v-list-item>
                    </v-list-group>
                </div>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            clipped-left
            dark>

            <v-app-bar-nav-icon v-if="showNavigationAndLoginInfo" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="hidden-xs-only">Portal</v-toolbar-title>

            <div v-if="axiosBusy">
              <v-progress-circular
                indeterminate
                color="amber"
              ></v-progress-circular>
            </div>

            <v-spacer/>
            <v-tooltip bottom v-if="showMaintenanceModeIcon">
                <template v-slot:activator="{ on }">
                    <v-icon class="mr-2 pulsating" color="error" large v-on="on">mdi-hammer-wrench</v-icon>
                </template>
                <span>Achtung: Wartungsmodus ist aktiv!</span>
            </v-tooltip>
            <template v-if="showNavigationAndLoginInfo">
                <p class="ma-0 text-caption mr-2" v-if="isLoggedIn">
                    Sitzung läuft ab am: {{formattedSessionExpirationTime}}
                </p>
                <v-btn v-if="isLoggedIn" class="primary" text @click="_logout"><v-icon>mdi-logout</v-icon>Logout</v-btn>
                <v-btn router to="/login" v-if="!isLoggedIn" text><v-icon>mdi-account-plus</v-icon>Login</v-btn>
                <v-btn router to="/signup" v-if="!isLoggedIn" text><v-icon>mdi-login</v-icon>Registrieren</v-btn>
            </template>
        </v-app-bar>
        <v-footer app dark>
            RATIS Portal
            <v-spacer/>
            <v-btn small router to="/datenschutz" text>
                Datenschutz
            </v-btn>
            <v-btn small router to="/impressum" text>
                Impressum
            </v-btn>
        </v-footer>
    </div>
</template>

<script>
    import AccessLevels from "../../constants/AccessLevels";
    import { mapGetters } from 'vuex';
    import { apiLogout } from "@/api/user.js";
    import UserRoles from "../../constants/UserRoles";
    import {isNotNullOrUndefined} from '../../helper/shared/TypeHelper';
    import {getTokenExpirationDate} from '../../helper/shared/JWTTokenHelper';
    import CaseTypes from '../../constants/CaseTypes';

    export default {
        name: "Navigation",
        data: () => ({
            drawer: null,
        }),
        methods: {
            async _logout()
            {
                const res = await apiLogout();

                if (res)
                {
                    this.$store.dispatch('user/logout');
                    this.$store.dispatch('setLoginPreviousRoute', null);
                    this.$router.push({name: 'login'});
                }
            },
            isNavItemAccessible(navItem)
            {
                let isAccessible = false;

                switch(navItem.accessLevel)
                {
                    case AccessLevels.LOGGED_OUT:
                        if (!this.isLoggedIn)
                            isAccessible = true;
                        break;
                    case AccessLevels.LOGGED_IN:
                        if (this.isLoggedIn)
                            isAccessible = true;
                        break;
                    case AccessLevels.MANAGER:
                        if ((UserRoles.MANAGER.value & this.user.role) === UserRoles.MANAGER.value)
                            isAccessible = true;
                        break;
                    default:
                        isAccessible = true;
                        break;
                }

                return isAccessible;
            }
        },
        computed: {
            ...mapGetters({
                isLoggedIn: "user/isLoggedIn",
                axiosBusy: "alert/axiosIsBusy",
                user: 'user/user',
                isMaintenanceModeActivated: 'isMaintenanceModeActivated',
                isAppAvailable: 'isAppAvailable'
            }),
            computedNavigationItems()
            {
                let items = [],
                me = this;

                let navigationItems = [
                    { icon: 'mdi-home', text: 'Startseite', route: '/', subLinks: false, accessLevel: AccessLevels.LOGGED_OUT},
                    { icon: 'mdi-account-plus', text: 'Registrierung', route: '/signup', subLinks: false, accessLevel: AccessLevels.LOGGED_OUT},
                    { icon: 'mdi-login', text: 'Login', route: '/login', subLinks: false, accessLevel: AccessLevels.LOGGED_OUT},
                    { icon: 'mdi-lock-question', text: 'Passwort vergessen', route: '/forgot-password', subLinks: false, accessLevel: AccessLevels.LOGGED_OUT},
                    { icon: 'mdi-view-dashboard-outline', text: 'Übersicht', route: '/dashboard', subLinks: false, accessLevel: AccessLevels.LOGGED_IN},
                ];

                navigationItems.forEach(function(navItem)
                {
                    if(me.isNavItemAccessible(navItem))
                    {
                        let newNavItem = {
                            icon: navItem.icon,
                            text: navItem.text,
                            accessLevel: navItem.accessLevel,
                        };

                        if(navItem.subLinks)
                        {
                            let accessibleSubLinks = [];

                            navItem.subLinks.forEach(function(subNavLink){
                                if(me.isNavItemAccessible(subNavLink))
                                {
                                    accessibleSubLinks.push(subNavLink);
                                }
                            });

                            newNavItem.subLinks = accessibleSubLinks;
                        }
                        else
                        {
                            newNavItem.subLinks = false;
                            newNavItem.route = navItem.route;
                        }

                        items.push(newNavItem);
                    }
                });

                let casesNavigationItem = this.casesNavigationItem;

                if (isNotNullOrUndefined(casesNavigationItem))
                    items.push(casesNavigationItem);

                return items;
            },
            formattedSessionExpirationTime()
            {
                let apiToken = localStorage.getItem('jwt_token');
                if (isNotNullOrUndefined(this.user) && isNotNullOrUndefined(apiToken))
                    return getTokenExpirationDate(apiToken).format('D.M.YYYY - H:mm') + ' Uhr';

                return '';
            },
            casesNavigationItem()
            {
                if (this.isLoggedIn && (this.user.cases.length > 0 || (isNotNullOrUndefined(this.user.akten) && this.user.akten.length > 0)))
                {
                    let dashboardEntry = {
                        icon: 'mdi-briefcase', text: 'Meine Fälle', subLinks: []
                    };

                    if (this.user.akten.length > 0)
                        dashboardEntry.subLinks.push({ icon: 'mdi-briefcase', text: 'Kündigung', route: '/kuendigung/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.EMISSION) || CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.EMISSION_COMPENSATION))
                        dashboardEntry.subLinks.push({ icon: 'mdi-engine', text: 'Abgasskandal', route: '/emissions/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.BANK_FEES))
                        dashboardEntry.subLinks.push({ icon: 'mdi-bank-transfer', text: 'Bankgebühren', route: '/bank-fees/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.GYM_FEES))
                        dashboardEntry.subLinks.push({ icon: 'mdi-weight', text: 'Fitnessstudiobeiträge', route: '/gym-fees/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.VEHICLE_FINANCING))
                        dashboardEntry.subLinks.push({ icon: 'mdi-car-arrow-left', text: 'KFZ-Widerruf', route: '/vehicle-financing/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.PHISHING))
                        dashboardEntry.subLinks.push({ icon: 'mdi-hook', text: 'Phishing', route: '/phishing/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.FLIGHTS))
                        dashboardEntry.subLinks.push({ icon: 'mdi-airplane', text: 'Flugverspätung', route: '/flights/case'});

                    if (CaseTypes.hasUserCaseOfCaseType(this.user, CaseTypes.PWNED))
                        dashboardEntry.subLinks.push({ icon: 'mdi-facebook', text: 'Facebook Datenskandal', route: '/pwned/case'});

                    return dashboardEntry;
                }

                return null;
            },
            showMaintenanceModeIcon()
            {
                return (this.user.role & UserRoles.ADMIN.value) === UserRoles.ADMIN.value
                    && this.isAppAvailable
                    && this.isMaintenanceModeActivated;
            },
            showNavigationAndLoginInfo()
            {
                return this.isAppAvailable
                    && this.$route.path.indexOf('direkt') === -1;
            }
        }
    }
</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .pulsating {
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
      transform: scale(1);
      animation: pulse 2s infinite;
  }

  @keyframes pulse {
      0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
      }

      70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
      }

      100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
      }
  }
</style>
