import moment from 'moment';
import {isNotNullOrUndefined} from './TypeHelper';

export function parseJwtToken(token)
{
    if (isNotNullOrUndefined(token))
    {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(character => {
            return '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    return null;
}

export function getTokenExpirationDate(token)
{
    let decodedApiToken = parseJwtToken(token);

    if (isNotNullOrUndefined(decodedApiToken))
    {
        //Exp represents the Seconds Since the Epoch
        return moment.unix(decodedApiToken.exp);
    }

    // if no JWTToken is in LocalStorage, return start of year
    // to ensure Session will be evaluated as invalid
    return moment().startOf('year');
}

export function isTokenValid(token)
{
    let sessionExpirationDate = getTokenExpirationDate(token);

    return moment().isSameOrBefore(sessionExpirationDate);
}