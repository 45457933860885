import {checkApiToken} from '../api/request';
import store from '../store';
import {isTokenValid} from '../helper/shared/JWTTokenHelper';

export function checkAuthentication(to, from, next)
{
    let isAuthenticated = checkApiToken();

    if(isAuthenticated)
    {
        if(store.getters.isInitializationFinished)
        {
            if(store.getters['user/isLoggedIn'])
                next();
            else
                next('/login');
        }
        else
            setTimeout(() => checkAuthentication(to, from, next), 500);
    }
    else
    {
        if (!isTokenValid(localStorage.getItem('jwt_token')))
        {
            store.dispatch('user/logout');
            store.dispatch('alert/add', {message: 'Ihre Sitzung ist abgelaufen! Bitte melden Sie sich erneut an.', type: 'error'});
        }

        store.dispatch('setLoginPreviousRoute', to.fullPath);

        next('/login');
    }
}

export function checkNoAuthentication(to, from, next)
{
    let isAuthenticated = checkApiToken();

    if (isAuthenticated)
        next('/dashboard');
    else
        next();
}


export function checkAppNotAvailable(to, from, next)
{
    if (store.getters.isInitializationFinished)
    {
        if (store.getters.isAppAvailable)
            next('/login');
        else
            next();
    }
    else
        setTimeout(() => checkAppNotAvailable(to, from, next), 500);
}
